import React from 'react'
import {LocaleLink} from '../helpers/locale'
import '../scss/pages/index.scss'

export default function FourOhFour () {

  return (
    <div className="four-oh-four">
      <LocaleLink to="/">
        <img src="/images/logo--bunker.svg"/>
      </LocaleLink>
      <h1>404</h1>
      <p>Sorry, we tried our damndest but we couldn&apos;t find the page you were looking for.</p>
      <p>There&apos;s no place like <LocaleLink to="/">Home</LocaleLink></p>
    </div>
  )
}
